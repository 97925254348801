import React from "react";
import Bg from "./images/bg.jpg";
import {Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons'

class Breadcrumb extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="jumbotron hero-spacer background-size-cover"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          <div className="container">
            <h1 className="text-custom my-4 text-center">{this.props.title}</h1>
            <nav aria-label="breadcrumb">
              <ol className="d-flex pl-0 justify-content-center">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-custom"> <FontAwesomeIcon className="mr-1" icon={faHome} /> Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {this.props.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Breadcrumb;
