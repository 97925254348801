import "./App.css";
import React from "react";
import Footer from "./components/footerComponent/footer";
import Home from "./components/homeComponent/home";
import Services from "./components/servicesComponent/services";
import Consultants from "./components/consultantsComponent/consultants";
import Contact from "./components/contactComponent/contact";
import ResearchDevelopment from "./components/researchDevelopmentComponent/researchDevelopment";
import { BrowserRouter, Switch, Link, Route } from "react-router-dom";
import PharmaceuticalsIntermediates from "./components/PharmaceuticalsIntermediates/pharmaceuticalsIntermediates";
import Logo from "./images/logo.png";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
              <div className="col-md-12 text-center py-4">
                <img src={Logo} alt="" />
              </div>
            <nav className="navbar navbar-expand-lg justify-content-center text-light navbar-primary bg-custom sticky-top">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              > 
                <svg style={{color: '#fff'}} width="1.6em" height="1.6em" viewBox="0 0 16 16" className="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="nav w-100 justify-content-center">
                  <li className="nav-item px-2">
                    <Link className="nav-link text-center text-white active" to="/">
                      HOME
                    </Link>
                  </li>
                  <li className="nav-item px-2">
                    <Link
                      className="nav-link text-center text-white"
                      to="/research-development"
                    >
                      RESEARCH & DEVELOPMENT
                    </Link>
                  </li>
                  <li className="nav-item px-2">
                    <Link className="nav-link text-center text-white" to="/services">
                      SERVICES
                    </Link>
                  </li>
                  <li className="nav-item px-2">
                    <Link className="nav-link text-center text-white" to="/consultants">
                      CONSULTANTS
                    </Link>
                  </li>
                  <li className="nav-item px-2">
                    <Link className="nav-link text-center text-white" to="/contact">
                      CONTACT US
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>

          <Switch>
            <Route path="/research-development">
              <ResearchDevelopment />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/consultants">
              <Consultants />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/bio-manufacturing-of-pharmaceuticals-intermediates">
            <PharmaceuticalsIntermediates />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
