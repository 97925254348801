import React from "react";
import FDA from "./images/fda.jpg";
import HC from "./images/healthcanada.jpg";
import { BrowserRouter, Link } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <footer className="bg-custom p-3">
          <div className="col-md-12 footer-banner text-center">
            <a href="http://www.fda.gov/Drugs/default.htm" target="_blank" rel="noopener noreferrer">
              <img src={FDA} alt=""/>
            </a>
            <a href="http://www.hc-sc.gc.ca/index-eng.php" target="_blank" rel="noopener noreferrer">
              <img src={HC} alt=""/>
            </a>
          </div>

          <div className="footer-content col-md-12">
            <p className="copyright text-white">
              Copyright © 2023{" "}
              <Link
                className="site-link text-white"
                to="/"
                title="Thera Biotech"
                rel="home"
              >
                Thera Biotech
              </Link>
            </p>
          </div>
        </footer>
      </BrowserRouter>
    );
  }
}

export default Footer;
