import React from "react";
import Office from "./images/office.jpg";
import Medicines from "./images/medicines.jpg";
import Laboratory from "./images/laboratory.jpg";
import BioManufacturing from "./images/bio-manufacturing.jpg";
import Bg from "./images/bg.jpg";
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="jumbotron hero-spacer bg-gradiant background-size-cover"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          <div className="container">
          <h1 className="text-custom my-4">Welcome to Thera-Biotech</h1>
          <p className="text-justify  my-4">
            Based in Canada, Thera-Biotech is a global regulatory compliance and
            quality assurance provider to the pharmaceuticals, biologics,
            natural health products, medical devices, cosmetics and allied
            industries. Thera-Biotech provides clients with expert regulatory
            and quality advice to ensure that the solutions are implemented
            methodically and remain sustainable. Both customized and generally
            accepted industry solutions are implemented at ease using services
            of Thera-Biotech.
          </p>
          </div>
        </div>

        <div className="container">
          <div className="row features">
            <div className="col-sm-4">
              <p className="text-center">
                <img
                  className="size-full rounded-circle img-responsive"
                  src={Office}
                  alt="Business associates shaking hands in office"
                  width="300"
                  height="300"
                />
              </p>
              <h4 className="text-custom mt-4 mb-3 text-center">
                Consulting &amp; Facilitation
              </h4>
              <p className="text-justify">
                We provide the consultation you need to solve any obstacle you
                encounter in a pharmaceutical, biologic, and laboratory set-up
                and have also provided our services to many reputed
                pharmaceutical facilities across the world. Examples of our work
                include process development, equipment and facility design, and
                process improvements.
              </p>
            </div>
            <div className="col-sm-4">
              <p  className="text-center">
                <img
                  className="size-full rounded-circle img-responsive"
                  src={Medicines}
                  alt="Colored pills isolated on white"
                  width="300"
                  height="300"
                />
              </p>
              <h4 className="text-custom mt-4 mb-3 text-center">Pharmaceuticals</h4>
              <p className="text-justify">
                We work for your pharmaceutical and bio-pharmaceutical company
                as per regulatory norms according to FDA and Health Canada
                guidelines. Some of our services include setting up the
                laboratory or manufacturing unit, inspections and auditing
                (internal and external).
              </p>
            </div>
            <div className="col-sm-4">
              <p className="text-center">
                <img
                  className="size-full rounded-circle img-responsive"
                  src={Laboratory}
                  alt="6"
                  width="300"
                  height="300"
                />
              </p>
              <h4 className="text-custom mt-4 mb-3 text-center">
                Research &amp; Development
              </h4>
              <p className="text-justify">
                We at Thera-Biotech believe that great research is essential to
                developing successful pharmaceutical products and we have
                dedicated more than 50 years in combined experience to R&amp;D
                both in industry and academia.
              </p>
              <p>
                We proudly support companies through our service as a Contract
                Research Organization (CRO) including executing biomedical
                research, pre-clinical testing and pharmaco-dynamic and
                pharmaco-kinetic studies.
              </p>
            </div>
          </div>
          <hr />
          <div className="row mb-4">
            <div className="col-lg-12">
              <h4 className="text-custom my-4">
                Bio-manufacturing of Pharmaceuticals / Intermediates
              </h4>
            </div>
            <div className="col-md-6">
              <p className="text-align-justify">
                We have the capability to manufacture drugs including
                antibiotics as well as their third or fourth generation
                intermediates in an industrial scale set-up. Of course all of
                these drugs and the intermediates will be manufactured in cGMP
                certified facilities by fully trained personnel. Many enzymes
                useful for bioconversions can also be produced in fully
                compliant facilities. These enzymes can also be immobilized
                using different media components for their effective use in
                conversions. Some of the major products that we can handle are
                given below:
              </p>
              <ul className="list-unstyled">
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Antibiotics (semi-synthetic ones)
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Enzymes (including immobilized ones)
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Antibodies (may be selected based on Phage display)
                </li>
              </ul>
              <p>
                <Link
                  className="btn bg-custom text-white btn-large"
                  to="/bio-manufacturing-of-pharmaceuticals-intermediates"
                >
                  Read More
                </Link>
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="alignnone size-full w-100"
                src={BioManufacturing}
                alt="5"
                width="700"
              />
            </div>
          </div>
          <hr />
          <div className="row mb-4">
            <div className="col-lg-12">
              <h4 className="text-custom mt-4 mb-4">Our Services</h4>
            </div>
            <div className="col-md-4">
              <div className="card">
  <div className="card-body">
                  <h6 className="mt-1 mb-0">
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}GMP, GLP &amp; GCP
                    compliance
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
  <div className="card-body">
                  <h6 className="mt-1 mb-0">
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "} Medical Devices
                    Regulation
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
  <div className="card-body">
                  <h6 className="mt-1 mb-0">
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    className="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "} Education, Training
                    and Inspection
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
