import React from "react";
import Breadcrumb from "./../breadcrumbComponent/breadcrumb";

class Contact extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      fields: {
        name: "",
        phone: "",
        email: "",
        message: "",
      },
      showLoader: false,
      showSuccessMessage: false,
      isSubmitting: false,
      isError: false,
    };
  }

  handleInputChange = (e) =>
    this.setState({
      fields: { ...this.state.fields, [e.target.name]: e.target.value },
    });

  async handleSubmit(e) {
    e.preventDefault();
    // console.log(this.state.fields);
    this.setState({
      showLoader: true
    });
    fetch(`/api/contact-mail.php`, {
      method: "POST",
      body: JSON.stringify(this.state.fields),
      // headers: {
      //   "Content-Type": "application/json",
      // },
    }).then(res => {
        this.setState({
          showLoader: false,
          showSuccessMessage: true
        });
      })
      .then(data => console.log(data))
      .catch(err => {
        console.error("Error:", err);
        this.setState({
          showLoader: false,
          showSuccessMessage: false
        });
      });


  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Contact Us" />
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-12">
              <h3 className="text-custom">Contact Thera Biotech</h3>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-md-6">
              <form
                id="contactForm"
                name="sentMessage"
                onSubmit={this.handleSubmit}
              >
                <div className="control-group form-group">
                  <div className="controls">
                    <label>Full Name:</label>
                    <br />
                    <input
                      id="name"
                      name="name"
                      className="form-control"
                      required=""
                      type="text"
                      onChange={this.handleInputChange}
                      data-validation-required-message="Please enter your name."
                      value={this.state.fields.name}
                    />
                    <p></p>
                    <p
                      className="help-block alert-name text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your name.
                    </p>
                  </div>
                </div>
                <div className="control-group form-group">
                  <div className="controls">
                    <label>Phone Number:</label>
                    <br />
                    <input
                      id="phone"
                      name="phone"
                      className="form-control"
                      required=""
                      type="tel"
                      onChange={this.handleInputChange}
                      data-validation-required-message="Please enter your phone number."
                      value={this.state.fields.phone}
                    />
                    <p
                      className="help-block alert-phone text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your phone number.
                    </p>
                    <p
                      className="help-block alert-phone-wrong text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your correct phone number.
                    </p>
                  </div>
                </div>
                <div className="control-group form-group">
                  <div className="controls">
                    <label>Email Address:</label>
                    <br />
                    <input
                      id="email"
                      name="email"
                      className="form-control"
                      required=""
                      type="email"
                      onChange={this.handleInputChange}
                      data-validation-required-message="Please enter your email address."
                      value={this.state.fields.email}
                    />
                    <p
                      className="help-block alert-email text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your email address.
                    </p>
                    <p
                      className="help-block alert-email-wrong text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your correct email address.
                    </p>
                  </div>
                </div>
                <div className="control-group form-group">
                  <div className="controls">
                    <label>Message:</label>
                    <br />
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      style={{ resize: "none" }}
                      cols="100"
                      required=""
                      rows="10"
                      onChange={this.handleInputChange}
                      data-validation-required-message="Please enter your message"
                      value={this.state.fields.message}
                    ></textarea>
                    <p
                      className="help-block alert-msg text-danger"
                      style={{ display: "none" }}
                    >
                      Please enter your phone number.
                    </p>
                  </div>
                </div>
                <p>
                  <br />
                  <button className="btn bg-custom text-white" type="submit">
                    Send Message
                  </button>
                </p>
                <p
                  className={this.showLoader ? 'help-block alert-loading text-danger' : 'help-block alert-loading text-danger d-none'}
                >
                  Loading…
                </p>
                <div
                  id="success"
                  className={this.showSuccessMessage ? 'text-success mb-4': 'text-success mb-4 d-none'}
                  style={{ fontWeight: "bold" }}
                >
                  Mail has been sent successfully.
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <p className="">
                Contact Thera Biotech today to schedule a time for a your
                questions and consultation!
              </p>

              <h3 className="text-custom">Thera Biotech</h3>
              <p>
                192 Citadel Meadow Close NW,
                <br />
                Calgary, AB T3G 4T4, Canada
              </p>
              <p className="mt-2 mb-4">
                <i className="fa fa-envelope-o"></i>{" "}
                <abbr title="Email">Email</abbr>:{" "}
                <a
                  className="text-custom"
                  href="mailto:navneet@thera-biotech.ca"
                >
                  navneet@thera-biotech.ca
                </a>
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2502.8738870351567!2d-114.18509564903633!3d51.14767667947736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537168f4d366d43f%3A0xfbb468c052c85700!2sThera-Biotech!5e0!3m2!1sen!2sin!4v1597512417918!5m2!1sen!2sin"
                style={{ border: "0", width: "100%", height: "326px" }}
                aria-hidden="false"
                title="Location on Google map"
              ></iframe>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
