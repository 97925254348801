import React from "react";
import BioManufacturing from "./images/bio-manufacturing.jpg";
import Breadcrumb from "./../breadcrumbComponent/breadcrumb";

class PharmaceuticalsIntermediates extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Bio-manufacturing of Pharmaceuticals / Intermediates" />
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-6">
              <p className="text-align-justify">
                We have the capability to manufacture drugs including
                antibiotics as well as their third or fourth generation
                intermediates in an industrial scale set-up. Of course all of
                these drugs and the intermediates will be manufactured in cGMP
                certified facilities by fully trained personnel. Many enzymes
                useful for bioconversions can also be produced in fully
                compliant facilities. These enzymes can also be immobilized
                using different media components for their effective use in
                conversions. Some of the major products that we can handle are
                given below:
              </p>
              <ul className="list-unstyled">
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Antibiotics (semi-synthetic ones)
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Enzymes (including immobilized ones)
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Antibodies (may be selected based on Phage display)
                </li>
              </ul>
              <p className="text-align-justify">
                The projects that we anticipate for manufacturing are based on
                fermentation. We have the technical expertise to manufacture
                antibiotics, antibodies and proteins using fermenters and
                downstream processing units including MF (micro-filtration), UF
                (ultra-filtration), RO (reverse osmosis), Chromatography,
                Crystallization and Drying. We can definitely include Recovery
                Units along side to limit wastage and recover more organics.
                Some of the examples of our bio-manufacturing are as given
                below:
              </p>
              <ul className="list-unstyled">
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Producing third generation antibiotics in 10,000 liters
                  fermenters
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Producing microbial enzymes (in 3000 Liter scale bio-reactors)
                </li>
                <li>
                  <svg
                    width="1.6em"
                    height="1.6em"
                    viewBox="0 0 16 16"
                    class="bi bi-check"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                    />
                  </svg>{" "}
                  Producing mammalian enzymes in lab reactors (20 liter scale)
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <img
                className="alignnone size-full w-100"
                src={BioManufacturing}
                alt="5"
                width="700"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PharmaceuticalsIntermediates;
