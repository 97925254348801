import React from "react";
import Biochemical from "./images/biochemical.jpg";
import DNA from "./images/dna-163466_640.jpg";
import Phage from "./images/phage.jpg";
import Protein from "./images/protein.jpg";
import Breadcrumb from "./../breadcrumbComponent/breadcrumb";

class ResearchDevelopment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Research & Development" />

        <div className="container rnd-container mt-4">
          <div className="row mb-4">
            <div className="col-md-4 img-portfolio">
            <div className="custom-img-border">
              <img
                className="w-100 img-responsive img-hover"
                src={Protein}
                alt=""
              />
            </div>
            </div>
            <div className="col-md-8 img-portfolio rnd-desc">
              <h3 className="mb-3 text-custom">Protein / Enzyme Purification</h3>
              <hr/>
              <p>
                <strong>
                  Expression &amp; Purification of Proteins / Enzymes from
                  microbial as well as mammalian Cultures
                </strong>
              </p>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check-circle-o"></i> Production of
                  recombinant cell cultures in shake flasks, lab fermenters or
                  big production scale vessels
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Purification
                  techniques including Gel&nbsp;Filtration, Ion Exchange,
                  Affinity purification and covalently tagged purification
                  techniques
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Removal of the tags
                  after purification and proper folding of recombinant proteins
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Enzyme Assays as well
                  as Identification of the proteins using Mass Spectrometry
                </li>
              </ul>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-8 img-portfolio rnd-desc">
              <h3 className="mb-3 text-custom">Phage Display</h3>
              <hr/>
              <p>
                <strong>Proteomics including Phage display library</strong>
              </p>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check-circle-o"></i> Can analyze the
                  different proteases for their site of catalysis using phage
                  display
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Can analyze the
                  substrates for the proteases using phage display
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Can generate reliable
                  antibodies against targets using phage display
                </li>
              </ul>
            </div>
            <div className="col-md-4 img-portfolio">
            <div className="custom-img-border">
              <img className="img-responsive img-hover w-100" src={Phage} alt="" />
            </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-4 img-portfolio">
            <div className="custom-img-border">
              <img className="w-100 img-responsive img-hover" src={DNA} alt="" />
            </div>
            </div>
            <div className="col-md-8 img-portfolio rnd-desc">
              <h3 className="mb-3 text-custom">Genomics</h3>
              <hr/>
              <p>
                <strong>
                  Genomics including constructing&nbsp;vectors, screening of
                  genomic libraries and analyses.
                </strong>
              </p>
              <p>
                <strong>
                  Mammalian cell culture as well as microbial cultures including
                  bacteria,&nbsp;fungi and bacteriophages.
                </strong>
              </p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-8 img-portfolio rnd-desc">
              <h3 className="text-custom">Biochemical Testing</h3>
              <hr/>
              <p>
                <strong>
                  Can analyze the samples to determine biochemical content using
                  the following techniques
                </strong>
              </p>
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-check-circle-o"></i> HPLC &amp; FPLC
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Mass Spectrometry
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> DNA &amp; Protein
                  Electrophoresis
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Western Blotting
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Titrations (Karl
                  Fisher’s)
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Volumetric Analysis
                </li>
                <li>
                  <i className="fa fa-check-circle-o"></i> Moisture Content
                </li>
              </ul>
              <p>
                <strong>
                  Many more methods &amp; techniques can be used as well.
                </strong>
              </p>
            </div>
            <div className="col-md-4 img-portfolio">
            <div className="custom-img-border">
              <img
                className="w-100 img-responsive img-hover"
                src={Biochemical}
                alt=""
              />
            </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResearchDevelopment;
