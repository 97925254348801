import React from "react";
// import Biochemical from "./images/biochemical.jpg";
// import DNA from "./images/dna-163466_640.jpg";
// import Phage from "./images/phage.jpg";
// import Protein from "./images/protein.jpg";
import Breadcrumb from "./../breadcrumbComponent/breadcrumb";
import BagCircle from "./bagCircle";

class ResearchDevelopment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Services" />

        <div className="container mt-4">
          <div className="row">
            <div className="col-md-4">
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">
                    GMP, GLP &amp; GCP compliance
                  </h4>
                  <p>
                    We audit facilities manufacturing drugs; carrying out
                    in-vitro PK / PD studies (as well as regular laboratory
                    testing); and clinical testing of the drugs (phase 1 to
                    phase 4) as per USFDA &amp; Health Canada guidelines. We
                    also work as internal auditors to help the industries set-up
                    as per regulatory guidelines so that they don’t have any
                    trouble going through the external audits.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">
                    Auditing and Validation of facilities, equipment and methods
                  </h4>
                  <p>
                    These services can be provided for facilities, equipment as
                    well as methods as per the required regulatory norms. The
                    validation services can be both retrospective as well as
                    prospective. Our consultants are well versed in all of
                    these.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">Education</h4>
                  <p>
                    Our consultants have a lot of experience with industry as
                    well as academia. Therefore, they are ideal for providing
                    education in the fields of manufacturing as well as
                    pharmaceutical / medical devices regulation.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <p></p>
            </div>
            <div className="col-md-4">
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">
                    Regulatory Submissions to Health Canada and FDA
                  </h4>
                  <p>
                    Submitting the documents to regulatory agencies for approval
                    of manufacturing, testing or clinical trails is very
                    crucial. The main reasons are the volume of the documents,
                    the formatting and the content as per regulatory guidelines.
                    We are experienced in doing that and would definitely
                    involve experts who will not only help you put together
                    those documents but will make sure that all requirements are
                    met.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">
                    Documentation (SOP Preparation etc.)
                  </h4>
                  <p>
                    We have experts in this field and ensure that our services
                    will be up to the mark and documents will definitely meet
                    the required standards.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">Training</h4>
                  <p>
                    We can also provide training in the fields of setting up the
                    manufacturing plant, manufacturing, testing as well as
                    clinical trials. Very soon we are going to start some
                    training programs in this area.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <p></p>
            </div>
            <div className="col-md-4">
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">
                    Compliance with ISO 9000, ISO 14000 and ISO 13485 guidelines
                  </h4>
                  <p>
                    International Organization for Standards based in Geneva,
                    Switzerland is the body that has published more than 21000
                    standards. The above said standards i.e. ISO 9000, ISO 14000
                    &amp; ISO 13485 are more important for the quality
                    management, environment systems of pharmaceutical and
                    medical device companies. We can do external as well as
                    internal auditing for your company so that it can get one of
                    these certificates and be compliant.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <div className="media  mb-2">
                <div className="pull-left">
                  <span className=" fa-2x">
                  <BagCircle />
                  </span>
                </div>
                <div className="media-body">
                  <h4 className="text-custom">Medical Devices Regulation</h4>
                  <p>
                    We can implement as well audit ISO 13485 standards for a
                    medical device manufacturer. All the regulatory norms for
                    the manufacturing as well as testing of the medical device
                    can be implemented and audited for compliance.
                  </p>
                  <p></p>
                </div>
                <p></p>
              </div>
              <p></p>
            </div>
            <p></p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResearchDevelopment;
