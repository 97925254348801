import React from "react";
import Allen from "./images/allen.jpg";
import Navneet from "./images/navneet.jpg";
import Derrick from "./images/derrick.jpg";
import Syme from "./images/syme.jpg";
import Breadcrumb from "./../breadcrumbComponent/breadcrumb";

class ResearchDevelopment extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Breadcrumb title="Consultants" />

        <div class="container">
          <div class="row consultant mb-4">
            <div class="col-sm-3">
              <div className="custom-img-border">
                <img
                  class="w-100 size-full"
                  src={Navneet}
                  alt="navneet-sharma"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <h4 className="text-custom">Navneet Sharma</h4>
              <h6>
                Ph.D. &amp; MBA
                <br />
                President &amp; CEO
              </h6>
              <p>
                He is an industry professional with many years of experience in
                the development of processes for production of semi-synthetic
                antibiotics and immobilized industrial enzymes. He has spent
                many years doing biochemical research as well, resulting in many
                publications and processes.
              </p>
              <p>
                Presently, he is an Adjunct Faculty at University of Calgary but
                has worked for more than 10 years with DSM Anti-Infectives,
                Max-Gb as well as Max India in the roles of Process / Product
                Development, Regulatory, QA as well as R&amp;D. He has a good
                knowledge base in the fields of Biochemistry &amp; Molecular
                Biology as well as Microbial Fermentation.
              </p>
              <p>
                He has helped many organizations to get approval with respect to
                different regulatory norms. He has also worked in the field of
                Auditing and Validation of facilities, methods and equipments.
              </p>
            </div>
          </div>

          <hr />
          <h2 class="text-custom text-center mb-4">Board of Advisors</h2>
          <div class="row consultant mb-4">
            <div class="col-sm-3">
              <div className="custom-img-border">
                <img
                  class="w-100 size-full"
                  src={Derrick}
                  alt="derrick-rancourt"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <h4 className="text-custom">Dr. Derrick Rancourt</h4>
              <p>
                He is a Professor in the Depts. of Oncology, Biochemistry &amp;
                Molecular Biology and Medical Genetics, University of Calgary.
                He received his BSc and PhD in Biochemistry from the University
                of Guelph and Queens University respectively and postdoc’d in
                the laboratory of Nobel Laureate Dr. Mario Capecchi.
              </p>
              <p>
                He is the Director of the University of Calgary’s Centre for
                Mouse Genomics, which specializes in the generation of
                transgenic and knockout mice. His research program revolves
                around the derivation, expansion, differentiation and genetic
                manipulation of mouse and human pluripotent stem cells,
                including embryonic stem cells and induced pluripotent stem
                cells.
              </p>
              <p>
                Over the past several years he has been developing bioprocesses
                for generating bone and cartilage tissue from stem cells for
                transplantation. An important feature of the research is the
                development of methods for expanding and differentiating murine
                and human pluripotent stem cells in stirred suspension
                bioreactors.
              </p>
            </div>
            <p>&nbsp;</p>
          </div>
          <div class="row consultant mb-4">
            <div class="col-sm-3">
              <div className="custom-img-border">
                <img
                  class="w-100 size-medium"
                  src={Allen}
                  alt="allen-davidoff"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <h4 className="text-custom">Dr. Allen W. Davidoff</h4>
              <p>
                Currently President and CEO of XORTX Pharma Corp.(2013-).
                Formerly, Chief Scientific Officer, VP Product Development and
                co-founder of Stem Cell Therapeutics Corp. (7 yrs). Prior to
                Stem Cell Therapeutics Corp., Senior Scientist and Head of
                Pharmacology at Cardiome Pharma Corp.
              </p>
              <p>
                Dr. Davidoff has 14+ years of therapeutic drug development
                experience with a focus on clinical and regulatory developmental
                affairs, in the US, Canada, Germany, Denmark, Argentina and
                India. Senior management and leadership experience includes
                pharmaceutical non-clinical and clinical R&amp;D including 2
                investigational new drug (“IND”) applications or supplemental
                IND’s, 2 phase I studies (4 multi-country), 7 phase II studies,
                and 1 NDA.
              </p>
              <p>
                Dr. Davidoff led pharmacology, non-clinical development and
                clinical development of Congestive Heart Failure, Gout, Stroke,
                Traumatic Brain Injury and Multiple Sclerosis therapy programs.
                He has extensive Regulatory and Clinical experience advancing 4
                small molecule Cardiovascular drugs and 3 biologic drug based
                Stem Cell therapeutic programs from laboratory evidence through
                a variety of stages of clinical trials in 6 Countries.
              </p>
            </div>
            <p>&nbsp;</p>
          </div>

          <div class="row consultant  mb-4">
            <div class="col-sm-3">
              <div className="custom-img-border">
                <img
                  class="alignnone size-full w-100"
                  src={Syme}
                  alt="derrick-rancourt"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <h4 className="text-custom">Dr. Rachel Syme</h4>
              <p>
                Dr. Syme is the Assistant Director with CIHR’s Institute of
                Cancer Research. She received her BSc (Honours) at Queen’s
                University, MSc (Medical Genetics) at the University of Calgary,
                and her PhD (Microbial Immunology) at the University of Calgary.
                She carried out post-doctoral work at the Tom Baker Cancer
                Centre focusing on dendritic cells and cellular based cancer
                vaccines. She spent a number of years as manager of the Clinical
                Trials Unit at the Tom Baker Cancer Centre prior to moving to a
                provincial position as Operations Leader of the Alberta Clinical
                Cancer Research Unit and then as Executive Director of Research
                Administration with Alberta Health Services. She currently holds
                an adjunct assistant professor position with the Department of
                Oncology at the University of Calgary. She also remains a
                coordinator and lecturer with the University’s Master of
                Biomedical Technology Program. Her present research interests
                include research recruitment and clinical trials impact and
                administration. She serves on N2’s board of Directors as well as
                a member of the Mount Royal Univeristy’s Research Ethics Board.
              </p>
            </div>
            <p>&nbsp;</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResearchDevelopment;
