import React from "react";
// import Biochemical from "./images/biochemical.jpg";
// import DNA from "./images/dna-163466_640.jpg";
// import Phage from "./images/phage.jpg";
// import Protein from "./images/protein.jpg";


class BagCircle extends React.Component {
  render() {
    return (
      <svg
        width="1.2em"
        height="1.2em"
        viewBox="0 0 16 16"
        class="bi bi-bag-plus mt-1 mr-3 text-custom"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"
        />
        <path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z" />
        <path
          fill-rule="evenodd"
          d="M8 7.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"
        />
        <path
          fill-rule="evenodd"
          d="M7.5 10a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-2z"
        />
      </svg>
    );
  }
}

export default BagCircle;